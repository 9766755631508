import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import './loading.css';
const Loading = () => {
    const [fontSize, setFontSize] = useState(500);
    useEffect(() => {
        // Função para ajustar o fontSize com base no tamanho da tela
        const adjustFontSize = () => {
            const width = window.innerWidth;
            if (width > 1200) {
                setFontSize(350); // Para telas grandes, o fontSize é fixo em 350px
            }
            else {
                // Para telas menores, o tamanho da fonte será proporcional ao tamanho da tela
                const newSize = (width / 1200) * 350;
                setFontSize(newSize);
            }
        };
        // Ajuste inicial ao carregar a página
        adjustFontSize();
        // Recalcular o fontSize quando a janela for redimensionada
        window.addEventListener('resize', adjustFontSize);
        // Limpar o evento de redimensionamento ao desmontar o componente
        return () => {
            window.removeEventListener('resize', adjustFontSize);
        };
    }, []);
    return (_jsxs("div", { className: "loading-container", children: [_jsx("h1", { style: { fontSize: `${fontSize}%` }, className: "animated-text", children: `<HelloWorld/>_`.split('').map((char, index) => (_jsx("span", { className: "letter", style: {
                        animation: `fadeIn 0.1s ${index * 0.1}s forwards`,
                    }, children: char != '_' ? char : (_jsx("span", { className: "underline", children: char }, index)) }, index))) }), _jsx("h2", { style: { fontSize: `${fontSize}%` }, className: "animated-text", children: 'Bem-vindo ao meu portfólio'.split('').map((char, index) => (_jsx("span", { className: "letter", style: {
                        animation: `fadeIn 0.1s ${index * 0.1}s forwards`,
                    }, children: char }, index))) })] }));
};
export default Loading;
