// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.animated-text {
    margin: 0;
}

.letter {
    opacity: 0;
    display: inline;
    animation: fadeIn 0.1s ease-in-out;
}

.letter:hover {
    opacity: 0;
    display: inline;
    animation: fadeIn 0.1s ease-in-out;
}

.underline {
    opacity: 1;
    animation: underlineInOut 1s infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes underlineInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/Home/Animation/loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,UAAU;IACV,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,UAAU;IACV,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,UAAU;IACV,qCAAqC;AACzC;;AAEA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".loading-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n.animated-text {\n    margin: 0;\n}\n\n.letter {\n    opacity: 0;\n    display: inline;\n    animation: fadeIn 0.1s ease-in-out;\n}\n\n.letter:hover {\n    opacity: 0;\n    display: inline;\n    animation: fadeIn 0.1s ease-in-out;\n}\n\n.underline {\n    opacity: 1;\n    animation: underlineInOut 1s infinite;\n}\n\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n        transform: translateY(10px);\n    }\n    100% {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n\n@keyframes underlineInOut {\n    0% {\n        opacity: 0;\n    }\n    50% {\n        opacity: 1;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
