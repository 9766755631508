import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import './pageSlider.css';
function PageSlider() {
    const pageSliderRef = useRef(null);
    const lastEventTime = useRef(0);
    const isMouseDown = useRef(false);
    const debounceTime = 300;
    const canGenerateDrops = useRef(true);
    useEffect(() => {
        const pageSlider = pageSliderRef.current;
        if (pageSlider) {
            const mouseClicked = (event) => {
                isMouseDown.current = event.type === 'mousedown';
            };
            const handleClick = (event) => {
                const currentTime = Date.now();
                if (currentTime - lastEventTime.current < debounceTime)
                    return;
                if (isMouseDown.current && canGenerateDrops.current) {
                    canGenerateDrops.current = false;
                    const rect = pageSlider.getBoundingClientRect();
                    const x = event.clientX - rect.left;
                    const y = event.clientY - rect.top;
                    pageSlider.addEventListener('mousemove', handleClick);
                    for (let i = 0; i < 20; i++) {
                        const drop = document.createElement('div');
                        drop.classList.add('waterDrop');
                        // Posição inicial (mais espalhada)
                        const startX = x + (Math.random() - 0.5) * 100; // Maior aleatoriedade no eixo X
                        const startY = y + (Math.random() - 0.5) * 100; // Maior aleatoriedade no eixo Y
                        drop.style.left = `${startX}px`;
                        drop.style.top = `${startY}px`;
                        pageSlider.appendChild(drop);
                        // Simulação de física
                        let velocityY = 0; // Velocidade inicial no eixo Y
                        const gravity = 0.5; // Gravidade (aceleração)
                        const floor = rect.height; // Altura máxima da queda
                        const jumpForce = -10; // Força inicial para o "pulo" (direção para cima)
                        let velocityX = (Math.random() - 0.5) * 4; // Maior variação no movimento horizontal
                        let goingUp = true; // Variável para saber se o pingo está subindo ou caindo
                        const animateDrop = () => {
                            if (goingUp) {
                                // Durante o pulo, aplicamos uma força negativa no eixo Y
                                velocityY += gravity; // A gravidade aumenta a velocidade na direção para baixo
                            }
                            else {
                                // Depois do pulo, a gravidade começa a puxar o pingo para baixo
                                velocityY += gravity; // Aceleração devido à gravidade
                            }
                            const currentY = parseFloat(drop.style.top) + velocityY;
                            const currentX = parseFloat(drop.style.left) + velocityX;
                            if (goingUp && velocityY >= 0) {
                                // Quando o pingo começar a cair, muda o estado para 'caindo'
                                goingUp = false;
                            }
                            if (currentY >= floor) {
                                // Quando o pingo toca o chão, removemos ele
                                drop.remove();
                            }
                            else {
                                drop.style.position = 'fixed'; // Definindo posição absoluta
                                drop.style.left = `${currentX}px`;
                                drop.style.top = `${currentY}px`;
                                // Tornar o movimento mais imprevisível horizontalmente
                                velocityX += (Math.random() - 0.5) * 0.2; // Tornar o movimento horizontal mais instável
                                requestAnimationFrame(animateDrop); // Continua a animação
                            }
                        };
                        // Aplicar a força de "pulo" para cima inicial
                        velocityY = jumpForce; // Força para começar o pulo
                        requestAnimationFrame(animateDrop); // Iniciar a animação
                    }
                    lastEventTime.current = currentTime;
                    // Após o tempo de debounce, permitimos a criação de pingos novamente
                    setTimeout(() => {
                        canGenerateDrops.current = true;
                    }, debounceTime);
                }
                else if (!isMouseDown.current) {
                    pageSlider.removeEventListener('mousemove', handleClick);
                }
            };
            pageSlider.addEventListener('mousedown', mouseClicked);
            pageSlider.addEventListener('mouseup', mouseClicked);
            pageSlider.addEventListener('mousedown', handleClick);
            return () => {
                pageSlider.removeEventListener('mousedown', mouseClicked);
                pageSlider.removeEventListener('mouseup', mouseClicked);
                pageSlider.removeEventListener('mousedown', handleClick);
            };
        }
    }, []);
    return (_jsxs("div", { className: "pageSlider", ref: pageSliderRef, children: [_jsx("div", { className: "waterButton", children: '<' }), _jsx("div", { className: "waterButton", children: '>' })] }));
}
export default PageSlider;
