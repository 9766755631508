// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
    color: #0e0e0e;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/app.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".app {\n    color: #0e0e0e;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
